const palettes = {
  FORECAST_RAINFALL: "af0000,eb1e00,ff6400,ffb300,ffeb00,9beb4a,33db80,00b4ff,0064ff,000096",
  LANDCOVER: "006400,ffbb22,ffff4c,f096ff,fa0000,b4b4b4,f0f0f0,0064c8",
};

function linearPalette(palette, startValue, endValue) {
  const colors = palette.split(",");
  const step = (endValue - startValue) / (colors.length - 1);
  let value = startValue - step;

  return colors.map((color) => {
    value += step;

    return { color, value };
  });
}

export { palettes, linearPalette };
