import "./App.css";
import { Routes, Route } from "react-router-dom";
import { DataLibrary } from "./components/DataLibrary/DataLibrary";
import { AppNavbar } from "./components/DataLibrary/AppNavbar";
import { Footer } from "./components/Footer";
import "./styles.css";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsmobile from "./aws-exports";
import { Pricing } from "./components/Pricing/Pricing";
import { Monitoring } from "./components/Monitoring/Monitoring";
Amplify.configure(awsmobile);

function App() {
  return (
    <div className="App">
      <AppNavbar />
      <Routes>
        <Route path="/" element={<DataLibrary />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="monitor" element={<Monitoring />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default withAuthenticator(App);
