import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
require("highcharts/modules/exporting")(Highcharts);

export const TimeSeries = (props) => {
  let subset = [];
  let final = [];

  for (let i in props.data) {
    subset.push(props.data[i]["date"]);
    subset.push(props.data[i][props.type]);
    final.push(subset);
    subset = [];
  }
  const options = {
    series: [
      {
        data: final,
        color: "#0039E6",
        id: "dataseries",
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      filename: props.ward + "_" + props.type,
    },
    rangeSelector: {
      inputEnabled: false,
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          moment(new Date(this.x)).subtract(0, "dates").format("DD") +
          " " +
          moment(new Date(this.x)).format("MMM") +
          " " +
          moment(new Date(this.x)).format("YYYY") +
          ": </b>" +
          this.y
        );
      },
    },
    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        useHTML: true,
        style: {
          fontSize: "10px",
          padding: "10px",
        },
      },
    },
    yAxis: {
      title: {
        text: props.type,
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
    />
  );
};
