import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { Spinner, Button, Tooltip } from "reactstrap";
import { Switch } from "./Switch";
import { IoMdArrowBack } from "react-icons/io";
import { Auth } from "aws-amplify";
import "../../styles.css";
import { changeWardName,changeDistrictName } from "../../redux/action";
import { useDispatch,useSelector} from "react-redux";



export const ZoomableMap = ({
  setWardHover,
  setLayer,
  layer,
  setSwitchButton,
  wardHover,
  switchButton,
}) => {
  let ee = require("@google/earthengine");
  let map = useRef();
  let [loadingstatus, setloadingstatus] = useState(false);
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const wardName = useSelector((state) => state.wardName);
  const dispatch = useDispatch();


  useEffect(() => {
    if (switchButton !== false) {
      setLayer("Rainfall");
    } else {
      setLayer("Landcover");
    }
  }, [switchButton, setLayer]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleBack = () => setTooltipOpen(!tooltipOpen);
  const handleBack = () => {
    map.current.setCenter(new window.google.maps.LatLng(14.8628, 28.2176));
    map.current.setZoom(5);
    setWardHover("");
    dispatch(changeWardName(null));
    dispatch(changeDistrictName(null));
    map.current.overlayMapTypes.setAt(1, null);
  };

  useEffect(() => {
    map.current = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 14.8628, lng: 28.2176 },
      zoom: 5,
      streetViewControl: false,
      mapTypeControl: false, //comment this to see map/satellite switch
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_CENTER, //TOP_CENTER
      },
      zoomControl: false, //make it true to enable
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER,
      },
      fullscreenControl: false,
      fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP,
      },
      options: {
        gestureHandling: "greedy",
      },
      scaleControl: false,
    });
    let shapefilecentroid = { lat: 14.8628, lng: 28.2176 };
    let geocoder = new window.google.maps.Geocoder();
    geocodeAddress(geocoder, shapefilecentroid);
  
  }, []);

  //to load landcover map
  const addEELayer = useCallback(
    (index, eeMapConfig, name) => {
      // Create the map layer type at a unique index
      var eeTileSource = new ee.layers.EarthEngineTileSource(eeMapConfig);
      var overlay = new ee.layers.ImageOverlay(eeTileSource);
      //removes whatever layer is applied at that index
      map.current.overlayMapTypes.setAt(index, null);
      map.current.overlayMapTypes.insertAt(index, overlay);
    },
    [ee]
  );

  useEffect(() => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const config = {
        headers: { Authorization: jwtToken },
      };

      async function name() {
        setloadingstatus(true);
        axios.get("/api/climate/wardBoundary", config).then((data) => {
          map.current.data.forEach((feature) => {
            map.current.data.remove(feature);
          });

          map.current.data.setStyle(function (feature) {
            return {
              fillColor: null,
              fillOpacity: 0,
            };
          });

          map.current.data.addGeoJson(data.data);
          if (wardName == null) {
            map.current.data.setStyle(function (feature) {
              return {
                fillOpacity: 0,
                strokeWeight: 1,
              };
            });

            map.current.data.addListener("mouseover", function (event) {
              setWardHover(event.feature.getProperty("NAME_3"));

              map.current.data.overrideStyle(event.feature, {
                fillOpacity: 0,
                strokeWeight: 3,
                fillColor: null,
              });
            });
            map.current.data.addListener("mouseout", (event) => {
              map.current.data.overrideStyle(event.feature, {
                fillOpacity: 0,
                strokeWeight: 1,
              });
            });
            map.current.data.addListener("click", function (event) {
              if (event.feature.getProperty("NAME_3") !== undefined) {
                dispatch(changeWardName(event.feature.getProperty("NAME_3")));
                dispatch(changeDistrictName(event.feature.getProperty("NAME_2")));
                map.current.data.overrideStyle(event.feature, {
                  fillOpacity: 0,
                  strokeWeight: 3,
                });
              }
            });
            setloadingstatus(false);
          }
        });
      }
      name();
      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  }, [wardName, setWardHover,dispatch]);

  useEffect(() => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      const config = {
        headers: { Authorization: jwtToken },
      };
      setTimeout(async () => {
        if (wardName == null) {
          // load all boundaries
          axios.get("/api/climate/wardBoundary", config).then((data) => {
            map.current.data.forEach((feature) => {
              map.current.data.remove(feature);
            });

            map.current.data.setStyle(function (feature) {
              return {
                fillColor: null,
                fillOpacity: 0,
              };
            });
            map.current.data.addGeoJson(data.data);
            if (wardName == null) {
              map.current.data.setStyle(function (feature) {
                return {
                  fillOpacity: 0,
                  strokeWeight: 1,
                };
              });

              map.current.data.addListener("mouseover", function (event) {
                setWardHover(event.feature.getProperty("NAME_3"));

                map.current.data.overrideStyle(event.feature, {
                  fillOpacity: 0,
                  strokeWeight: 3,
                  fillColor: null,
                });
              });
              map.current.data.addListener("mouseout", (event) => {
                map.current.data.overrideStyle(event.feature, {
                  fillOpacity: 0,
                  strokeWeight: 1,
                });
              });
              map.current.data.addListener("click", function (event) {
                if (event.feature.getProperty("NAME_3") !== undefined) {
                  map.current.data.overrideStyle(event.feature, {
                    fillOpacity: 0,
                    strokeWeight: 3,
                  });
                }
              });
              setloadingstatus(false);
            }
          });
        } else {
          //load the indices map and load only selected boundary
          axios
            .get(
              `/api/climate/selectedWardBoundary?ward_name=${wardName}`,
              config
            )
            .then((data) => {
              map.current.data.forEach((feature) => {
                map.current.data.remove(feature);
              });

              map.current.data.setStyle(function (feature) {
                return {
                  fillColor: null,
                  fillOpacity: 0,
                };
              });
              map.current.data.addGeoJson(data.data);
            });
          const {
            accessToken: { jwtToken },
          } = await Auth.currentSession();
          axios({
            method: "post",
            url: `/gee/${layer}`, //add the layer route here based on routes.js
            data: {
              region: wardName,
            },
            headers: {
              "Content-Type": "application/json",
              "access-control-allow-origin": "*",
              Authorization: jwtToken,
            },
          })
            .then((result) => {
              const dict = {
                mapid: result.data.mapToken.mapId,
                token: result.data.mapToken.token,
              };
              addEELayer(1, dict, layer);
              setloadingstatus(false);
            })
            .catch((error) => {
              console.log("url is triggered and error is", error);
              setloadingstatus(false);
            });
        }
      }, 2000);
      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  }, [wardName,layer,setWardHover,addEELayer]);

  const geocodeAddress = (geocoder, obj) => {
    geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: obj }, function (results, status) {
      if (status === "OK") {
        map.current.setCenter(new window.google.maps.LatLng(14.8628, 28.2176));
      } else {
        alert("Oops! Too many API hits/ Invalid lat long");
      }
    });
  };
  return (
    <>
      {loadingstatus ? (
        <div style={{ top: "50vh", left: "25vw", position: "fixed" }}>
          <Spinner />
        </div>
      ) : (
        ""
      )}

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-1">
            <Button
              className="shadow mt-3"
              onClick={handleBack}
              style={{
                borderColor: "#ff0000",
                backgroundColor: "#ff0000",
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                alignItems: "center",
                padding: "0px",
              }}
            >
              <IoMdArrowBack size={22} style={{ color: "#fff" }} id="Back" />
              <Tooltip isOpen={tooltipOpen} target="Back" toggle={toggleBack}>
                Sudan Map
              </Tooltip>
            </Button>
          </div>
          <div className="col-md-8 mt-3">
            <h6>{wardHover}</h6>
          </div>
          <div className="col-md-1">
            {wardName !== null ? (
              <>
                <Switch
                  isOn={switchButton}
                  onColor="#ff0000"
                  handleToggle={() => setSwitchButton(!switchButton)}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div id="map_container"></div>
        <div id="map"></div>
      </div>
    </>
  );
};
