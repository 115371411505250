import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import "../../styles.css";
import Select from "react-select";
import { FiCheck } from "react-icons/fi";
import { AiOutlineDownload, AiOutlineShareAlt } from "react-icons/ai";
import { Auth } from "aws-amplify";
import { Column } from "./Column";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import getContent from "../../utils/htmlTemplate";
import Slider from "rc-slider";
import { changeStateName } from "../../redux/action";
import { changeDistrictName } from "../../redux/action";
import { changeWardName } from "../../redux/action";
import "rc-slider/assets/index.css";

export const Pricing = () => {
  const [triggerLevel, setTriggerLevel] = useState("0.2");
  const [exitLevel, setExitLevel] = useState("0");
  const [exitLabel, setExitLabel] = useState("Minimum");
  const [maxPayout, setMaxPayout] = useState("1");
  const [startMonth, setStartMonth] = useState("July");
  const [startMonthNum, setStartMonthNum] = useState("7");
  const [endMonth, setEndMonth] = useState("October");
  const [endMonthNum, setEndMonthNum] = useState("10");
  const [premium, setPremium] = useState(null);
  const [payoutAnalysis, setPayoutAnalysis] = useState([]);
  const [summary, setSummary] = useState([]);
  const [summaryDownload, setSummaryDownload] = useState([]);
  const [sumInsured, setSumInsured] = useState(120);
  const stateName = useSelector((state) => state.stateName);
  const [districts, setDistricts] = useState([]);
  const districtName = useSelector((state) => state.districtName);
  const [wards, setWards] = useState([]);
  const wardName = useSelector((state) => state.wardName);
  const [states, setStates] = useState([]);
  const dispatch = useDispatch();



 
  let trigger_options = [
    { value: "0.1", label: "0.1" },
    { value: "0.15", label: "0.15" },
    { value: "0.2", label: "0.2" },
    { value: "0.25", label: "0.25" },
    { value: "0.3", label: "0.3" },
    { value: "0.35", label: "0.35" },
    { value: "0.4", label: "0.4" },
    { value: "0.45", label: "0.45" },
    { value: "0.5", label: "0.5" },
    { value: "0.55", label: "0.55" },
    { value: "0.6", label: "0.6" },
    { value: "0.65", label: "0.65" },
    { value: "0.7", label: "0.7" },
    { value: "0.75", label: "0.75" },
    { value: "0.8", label: "0.8" },
    { value: "0.85", label: "0.85" },
    { value: "0.9", label: "0.9" },
    { value: "0.95", label: "0.95" },
    { value: "1", label: "1" },
  ];
  let exit_options = [
    { value: "0", label: "Minimum" },
    { value: "1", label: "1st Percentile" },
    { value: "5", label: "5th Percentile" },
    { value: "10", label: "10th Percentile" },
  ];
  let max_payout_options = [
    { value: "0.5", label: "0.5" },
    { value: "0.6", label: "0.6" },
    { value: "0.7", label: "0.7" },
    { value: "0.8", label: "0.8" },
    { value: "0.9", label: "0.9" },
    { value: "1", label: "1" },
  ];
  let months = [
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
  ];
  useEffect(() => {
    let didCancel = false;
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch("/api/climate/getStateNames", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setStates(data);
        });

      if (stateName !== null) {
        fetch("/api/climate/getDistrictNames?state_name=" + stateName, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setDistricts(data);
          });
        }
        if (districtName !== null) {
          fetch(
            "/api/climate/getWardNames?state_name=" +
            stateName +
            "&district_name=" +
            districtName,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setWards(data);
            });
        }


      if (
        stateName !== null &&
        districtName !== null &&
        wardName !== null &&
        triggerLevel !== null &&
        exitLevel !== null &&
        maxPayout !== null &&
        startMonthNum !== null &&
        endMonthNum !== null
      ) {
        if (parseInt(startMonthNum) < parseInt(endMonthNum)) {
          fetch(
            "/api/payout/customHistoricalPayout?state_name=" +
              stateName + "&district_name=" + districtName + "&ward_name="+wardName+
              "&maximum_payout=" +
              maxPayout +
              "&trigger=" +
              triggerLevel +
              "&exit=" +
              exitLevel +
              "&start_month=" +
              startMonthNum +
              "&end_month=" +
              endMonthNum,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setPayoutAnalysis(data);
            });
          fetch(
            "/api/payout/premium?state_name=" +
            stateName + "&district_name=" + districtName + "&ward_name="+wardName+
              "&maximum_payout=" +
              maxPayout +
              "&trigger=" +
              triggerLevel +
              "&exit=" +
              exitLevel +
              "&start_month=" +
              startMonthNum +
              "&end_month=" +
              endMonthNum,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setPremium(data["premium"]);
            });
          fetch(
            "/api/payout/summary?state_name=" +
            stateName + "&district_name=" + districtName + "&ward_name="+wardName+
              "&maximum_payout=" +
              maxPayout +
              "&trigger=" +
              triggerLevel +
              "&exit=" +
              exitLevel +
              "&start_month=" +
              startMonthNum +
              "&end_month=" +
              endMonthNum,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setSummary(data);
            });
          fetch(
            "/api/payout/downloadPricing?state_name=" +
            stateName + "&district_name=" + districtName + "&ward_name="+wardName+
              "&maximum_payout=" +
              maxPayout +
              "&trigger=" +
              triggerLevel +
              "&exit=" +
              exitLevel +
              "&start_month=" +
              startMonthNum +
              "&end_month=" +
              endMonthNum,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setSummaryDownload(data);
            });
        } else {
          alert("Check the months");
        }
      }

      if (!didCancel) {
        // Ignore if we started fetching something else
        console.log("....");
      }
    }
    genToken();
    return () => {
      didCancel = true;
    };
  }, [stateName,districtName,wardName, triggerLevel, exitLevel, maxPayout, startMonthNum, endMonthNum]);

  const downloadPdfDocument = () => {
    const input = getContent(
      stateName,
      districtName,
      wardName,
      triggerLevel,
      summary["trigger"],
      exitLabel,
      summary["exit"],
      maxPayout,
      premium,
      startMonth,
      endMonth,
      sumInsured
    );
    let div = document.createElement("div");
    div.innerHTML = input;
    let element = document.getElementById("chart");
    let clone = element.cloneNode(true);
    div.appendChild(clone);
    let opt = {
      margin: 1,
      filename: stateName+"_"+districtName+"_"+wardName + "_Termsheet.pdf",
      html2canvas: { scale: 2 },
    };
    html2pdf().set(opt).from(div).save();
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };

  let stateObject = [];
  let districtObject = [];
  let wardObject = [];

  for (let j in states) {
    let items = {};
    items["label"] = states[j];
    items["value"] = states[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    stateObject.push(json_obj);
  }
  for (let j in districts) {
    let items = {};
    items["label"] = districts[j];
    items["value"] = districts[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    districtObject.push(json_obj);
  }
  for (let j in wards) {
    let items = {};
    items["label"] = wards[j];
    items["value"] = wards[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    wardObject.push(json_obj);
  }


  return (
    <>
      <div
        className="row justify-content-center"
        style={{
          minHeight: "calc(100vh - 90px)",
        }}
      >
        <div
          className="col-md-3"
          style={{
            backgroundColor: "#E6ECFF",
            marginBottom: "10px",
            borderColor: "#f5f5f5",
          }}
        >
          <h6 className="mt-4 mb-3">Product Parameters</h6>
          <div className="row justify-content-center">
          <div className="row justify-content-center m-1">
            <div className="col-md-4" style={{ fontSize: "10px" }}>
              <Label>Select State</Label>
              <Select
                options={stateObject}
                value={{
                  value: stateName,
                  label: stateName,
                }}
                onChange={(obj) => {
                  dispatch(changeStateName(obj["value"]));
                  dispatch(changeDistrictName(null));
                  dispatch(changeWardName(null));                
                }}
                required
                styles={customStyles}
              />
            </div>{" "}
            <div className="col-md-4" style={{ fontSize: "10px" }}>
              <Label>Select District</Label>
              <Select
                options={districtObject}
                value={{
                  value: districtName,
                  label: districtName,
                }}
                onChange={(obj) => {
                  dispatch(changeWardName(null));
                  dispatch(changeDistrictName(obj["value"]));
                }}
                required
                styles={customStyles}
              />
            </div>{" "}
            <div className="col-md-4" style={{ fontSize: "10px" }}>
              <Label>Select Locality</Label>
              <Select
                options={wardObject}
                value={{
                  value: wardName,
                  label: wardName,
                }}
                onChange={(obj) => dispatch(changeWardName(obj["value"]))}
                required
                styles={customStyles}
              />
            </div>{" "}
          </div>
            <div
              className="col-md-5"
              style={{ fontSize: "11px", textAlign: "left" }}
            >
              <Label>Trigger Level (%ile)</Label>
              <FormGroup>
                <Select
                  options={trigger_options}
                  value={{
                    value: triggerLevel !== null ? triggerLevel : "",
                    label: triggerLevel !== null ? triggerLevel : "",
                  }}
                  onChange={(obj) => setTriggerLevel(obj["value"])}
                  required
                  styles={customStyles}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-md-5"
              style={{ fontSize: "11px", textAlign: "left" }}
            >
              <Label>Exit Level</Label>
              <FormGroup>
                <Select
                  options={exit_options}
                  value={{
                    value: exitLevel !== null ? exitLevel : "",
                    label: exitLabel !== null ? exitLabel : "",
                  }}
                  onChange={(obj) => {
                    setExitLevel(obj["value"]);
                    setExitLabel(obj["label"]);
                  }}
                  required
                  styles={customStyles}
                />
              </FormGroup>
            </div>
            <div
              className="col-md-5"
              style={{ fontSize: "11px", textAlign: "left" }}
            >
              <Label>Maximum Payout</Label>
              <FormGroup>
                <Select
                  options={max_payout_options}
                  value={{
                    value: maxPayout !== null ? maxPayout : "",
                    label: maxPayout !== null ? maxPayout : "",
                  }}
                  onChange={(obj) => setMaxPayout(obj["value"])}
                  styles={customStyles}
                  required
                />
              </FormGroup>
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-md-5"
              style={{ fontSize: "11px", textAlign: "left" }}
            >
              <Label>Start</Label>
              <FormGroup>
                <Select
                  options={months}
                  value={{
                    value: startMonthNum !== null ? startMonthNum : "",
                    label: startMonth !== null ? startMonth : "",
                  }}
                  onChange={(obj) => {
                    setStartMonth(obj["label"]);
                    setStartMonthNum(obj["value"]);
                  }}
                  required
                  styles={customStyles}
                />
              </FormGroup>
            </div>
            <div
              className="col-md-5"
              style={{ fontSize: "11px", textAlign: "left" }}
            >
              <Label>End</Label>
              <FormGroup>
                <Select
                  options={months}
                  value={{
                    value: endMonthNum !== null ? endMonthNum : "",
                    label: endMonth !== null ? endMonth : "",
                  }}
                  onChange={(obj) => {
                    setEndMonth(obj["label"]);
                    setEndMonthNum(obj["value"]);
                  }}
                  required
                  styles={customStyles}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10" style={{ fontSize: "12px" }}>
              <Label>
                Sum Insured: <b>{sumInsured} USD</b>
              </Label>
              <Slider
                min={50}
                max={300}
                defaultValue={120}
                step={10}
                marks={{ 50: 50, 300: 300 }}
                onChange={(e) => {
                  setSumInsured(e);
                }}
                trackStyle={{ backgroundColor: "blue", height: 5 }}
                handleStyle={{
                  borderColor: "red",
                  height: 15,
                  width: 15,
                  marginLeft: -5,
                  marginTop: -5,
                  backgroundColor: "black",
                }}
                railStyle={{ backgroundColor: "red", height: 5 }}
              />
            </div>
          </div>
          {summary.length !== 0 ? (
            <>
              <div className="row justify-content-around mt-4" id="summary">
                <div className="col-md-4">
                  <h6>Summary</h6>
                </div>
                <div className="col-md-2">
                  <div className="row">
                    <div className="col-md-6">
                      <a href="mailto:name@email.com?subject=Summary%20of%20Product%20Parameters">
                        {" "}
                        <div
                          className="shadow"
                          style={{
                            borderRadius: "50%",
                            borderColor: "#0039E6",
                            backgroundColor: "#0039E6",
                            height: "25px",
                            width: "25px",
                            justifyContent: "center",
                            display: "inline-flex",
                          }}
                        >
                          <AiOutlineShareAlt
                            size={17}
                            style={{ color: "#fff", marginTop: "4px" }}
                          />
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6">
                      <CSVLink
                        data={summaryDownload}
                        filename={stateName + "_" + districtName + "_" + wardName + "_Summary.csv"}
                      >
                        <div
                          className="shadow"
                          style={{
                            borderRadius: "50%",
                            borderColor: "#0039E6",
                            backgroundColor: "#0039E6",
                            height: "25px",
                            width: "25px",
                            justifyContent: "center",
                            display: "inline-flex",
                          }}
                        >
                          <AiOutlineDownload
                            size={17}
                            style={{ color: "#fff", marginTop: "4px" }}
                          />
                        </div>
                      </CSVLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-2">
                <div className="col-md-5">
                  <Card
                    body
                    style={{
                      backgroundColor: "#fff",
                      borderColor: "#f5f5f5",
                      padding: "5px",
                    }}
                  >
                    <div
                      className="row justify-content-center"
                      style={{ fontSize: "12px" }}
                    >
                      <FiCheck size={25} style={{ color: "#0039E6" }} />
                      Trigger
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          color: "#0039E6",
                        }}
                      >
                        {" "}
                        {summary["trigger"]}
                      </span>
                    </div>
                  </Card>
                </div>

                <div className="col-md-5">
                  <Card
                    body
                    style={{
                      backgroundColor: "#fff",
                      borderColor: "#f5f5f5",
                      padding: "5px",
                    }}
                  >
                    <div
                      className="row justify-content-center"
                      style={{ fontSize: "12px" }}
                    >
                      <FiCheck size={25} style={{ color: "#0039E6" }} />
                      Exit
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          color: "#0039E6",
                        }}
                      >
                        {summary["exit"]}
                      </span>
                    </div>
                  </Card>
                </div>
              </div>

              <Button
                onClick={downloadPdfDocument}
                className="mt-3 shadow"
                style={{
                  color: "#fff",
                  borderColor: "#0039E6",
                  backgroundColor: "#0039E6",
                  fontSize: "12px",
                }}
              >
                Download Termsheet
              </Button>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="col-md-9">
          {payoutAnalysis.length > 0 && premium !== null ? (
            <>
              <Card
                body
                className="mt-1"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  padding: "2px",
                }}
              >
                <CardBody>
                  <div className="row justify-content-between">
                    <div className="col-md-4 mt-5">
                      <CardTitle tag="h6" style={{ color: "#0039E6" }}>
                        Historical Payout Analysis
                        <CSVLink
                          data={payoutAnalysis}
                          filename={stateName + "_" + districtName + "_" + wardName + "_PayoutAnalysis.csv"}
                        >
                          <div
                            className="shadow"
                            style={{
                              borderRadius: "50%",
                              margin: "10px",
                              borderColor: "#0039E6",
                              backgroundColor: "#0039E6",
                              height: "22px",
                              width: "22px",
                              justifyContent: "center",
                              display: "inline-flex",
                            }}
                          >
                            <AiOutlineDownload
                              size={15}
                              style={{ color: "#fff", marginTop: "4px" }}
                            />
                          </div>
                        </CSVLink>
                      </CardTitle>
                    </div>
                    <div className="col-md-2">
                      <Card
                        body
                        className="shadow"
                        style={{
                          color: "#fff",
                          borderColor: "#0039E6",
                          marginRight: "10px",
                          backgroundColor: "#0039E6",
                          padding: "0px",
                        }}
                      >
                        <div className="row justify-content-center">
                          <FiCheck size={25} style={{ color: "#fff" }} />
                          <Label style={{ color: "#fff", fontSize: "12px" }}>
                            Premium Rate
                          </Label>
                          <span
                            style={{ fontWeight: "bold", fontSize: "25px" }}
                          >
                            {premium}%
                          </span>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div id="chart" className="col-md-8">
                      <Column
                        data={payoutAnalysis}
                        type={"Pricing"}
                        yAxis={"Payout (%)"}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </>
          ) : (
            <>
              <h5 className="mt-5">Please select ward name</h5>
            </>
          )}
        </div>
      </div>
    </>
  );
};
