import * as React from "react";
import Legend from "./Legend";
import { palettes } from "./Palettes";

export const RainfallLegend = () => {
  const precipitationPalette = palettes.FORECAST_RAINFALL.split(",");
  const paletteValues = ["100", "","","","","","","", "", "1000mm"];
  const dist_arr = [];
  const length = precipitationPalette.length; // user defined length

  for (let i = 0; i < length; i++) {
    dist_arr.push(1);
  }
  const legendName = "RAINFALL";
  return (
    <Legend
      palette={precipitationPalette}
      values={paletteValues}
      legendText={legendName}
      leftProp="42"
      dist_arr={dist_arr}
    />
  );
};