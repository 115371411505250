import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
export const DualAxesChart = (props) => {
  let subset = [];
  let final2 = [];
  let subset2 = [];
  let final = [];
  if (props) {
    // eslint-disable-next-line
    for (let k in props.data[0]) {
      for (let i in props.data[0]["ndvi"]) {
        subset2.push(props.data[0]["ndvi"][i]["date"]);
        subset2.push(props.data[0]["ndvi"][i]["ndvi"]);
        final2.push(subset2);
        subset2 = [];
      }
      for (let j in props.data[0]["rainfall"]) {
        subset.push(props.data[0]["rainfall"][j]["date"]);
        subset.push(props.data[0]["rainfall"][j]["rainfall"]);
        final.push(subset);
        subset = [];
      }
    }
  }
  const options = {
    series: [
      {
        data: final.sort(),
        yAxis: 1,
        type: "column",
        name: "Rainfall (mm)",
        color: "#ff9900",
      },
      {
        data: final2.sort(),
        color: "#0066ff",
        name: "NDVI",
      },
    ],
    title: {
      text: "",
    },
    subtitle: {
      text: "Drag to zoom",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          moment(new Date(this.x)).subtract(0, "dates").format("DD") +
          " " +
          moment(new Date(this.x)).format("MMM") +
          " " +
          moment(new Date(this.x)).format("YYYY") +
          "</b>: <b>" +
          this.y +
          "</b>"
        );
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "NDVI",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: "Rainfall (mm)",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: true,
      },
    ],
    xAxis: {
      type: "datetime",
      lineWidth: 1,
      lineColor: "#515151",
      tickColor: "#515151",
      tickInterval: 1000 * 3600 * 24 * 365,
      labels: {
        useHTML: true,
        style: {
          fontSize: "10px",
          padding: "10px",
        },
        formatter: function () {
          return moment(new Date(this.value))
            .subtract(0, "year")
            .format("YYYY");
        },
      },
    },
    chart: {
      zoomType: "x",
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
