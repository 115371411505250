import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import "../../styles.css";
import { Auth } from "aws-amplify";

export const AppNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [user, setUser] = useState(null);
  const signOut = async (e, obj) => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  Auth.currentSession().then((data) => {
    setUser(data.idToken.payload["email"]);
  });
  return (
    <div>
      <Navbar
        light
        expand="lg"
        className="shadow"
        style={{
          backgroundColor: "#fff",
          borderColor: "#fff",
          paddingTop: "10px",
          paddingLeft: "14px",
          borderRadius: "20px",
          boxSizing: "border-box",
        }}
      >
        <NavbarBrand href="/" style={{ color: "#0039E6" }}>
          <img
            style={{
              width: "80px",
              marginRight: "10px",
            }}
            src="logosf.jpg"
            alt="Card cap"
          />
          <img
            style={{
              width: "80px",
              marginRight: "10px",
            }}
            src="ilri.png"
            alt="Card cap"
          />
          Livestock Insurance
        </NavbarBrand>
        <NavbarToggler onClick={toggle} className="mr-2" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar style={{ paddingLeft: "15%" }}>
            <NavItem
              style={{
                paddingRight: "50px",
              }}
            >
              <NavLink
                to="/"
                style={({ isActive }) => ({
                  color: isActive ? "#0039E6" : "#9CA1B2",
                  fontWeight: isActive ? "bold" : "normal",
                  textDecoration: "none",
                  fontSize: "12px",
                })}
              >
                Data Library
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                paddingRight: "50px",
              }}
            >
              <NavLink
                to="/pricing"
                style={({ isActive }) => ({
                  color: isActive ? "#0039E6" : "#9CA1B2",
                  fontWeight: isActive ? "bold" : "normal",
                  textDecoration: "none",
                  fontSize: "12px",
                })}
              >
                Pricing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="/monitor"
                style={({ isActive }) => ({
                  color: isActive ? "#0039E6" : "#9CA1B2",
                  fontWeight: isActive ? "bold" : "normal",
                  textDecoration: "none",
                  fontSize: "12px",
                })}
              >
                Monitoring
              </NavLink>
            </NavItem>
          </Nav>
          <UncontrolledDropdown inNavbar>
            <DropdownToggle nav caret style={{ fontSize: "12px" }}>
              {user}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={signOut}>Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Collapse>
      </Navbar>
    </div>
  );
};
