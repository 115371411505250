import React, { Component } from "react";
import "../../Legend.css";
export default class Legend extends Component {
  render() {
    const values = this.props.values || [];
    const down_arr = this.props.down_arr || [];
    const { dist_arr } = this.props;
    return (
      <div className="legend">
        <div className="legend-palette">
          {this.renderPalette(this.props.palette, values, dist_arr, down_arr)}
          {this.props.unit ? (
            <div className="legend-block unit-block">
              <span className="legend-label">{this.props.unit}</span>
            </div>
          ) : null}
        </div>
        {this.props.children}
      </div>
    );
  }

  renderPalette(palette, values, dist_arr, down_arr) {
    return palette.map((color, index) => {
      return (
        <div
          className="legend-block"
          style={{ backgroundColor: "#" + color }}
          key={index}
        >
          <span className="legend-label" style={{ color: "#" + color }}>
            {values[index]}
          </span>
          <br />
          <span className="legend-downlabel" style={{ color: "#" + color }}>
            {down_arr[index]}
          </span>
        </div>
      );
    });
  }
}
