const awsmobile = {
  "aws_project_region": "ap-south-1",
  "aws_cognito_identity_pool_id": "ap-south-1:1b397add-2731-44e8-bf0c-9d38375015cc",
  "aws_cognito_region": "ap-south-1",
  "aws_user_pools_id": "ap-south-1_Crn0OS6Wu",
  "aws_user_pools_web_client_id": "77bqo3dju7humtlr76t6paubh5",
  "oauth": {},
  // "aws_mobile_analytics_app_region": "ap-south-1",
  // "aws_mobile_analytics_app_id": "9b7ffa0f54f74ef7856b33d0daa48014"
};
export default awsmobile;